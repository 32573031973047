import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { ADMIN_ADEPT_DATA, ADMIN_ADEPT_TESTS_MANAGE } from 'Consts/routes';

import { withVariables } from 'Utils/string';

import StyledComponent from 'Components/core/StyledComponent';
import PageContent from 'Components/layout/panel/PageContent';
import Page from 'Components/layout/panel/Page';
import TabNavigation from 'Components/layout/panel/TabNavigation';
import Editor from 'Components/admin/adeptTests/Editor';
import { TAB_ADEPT_TESTS } from 'Components/pages/admin/AdeptData/component';

export const TAB_DATA = 'data';

export default class AdminAdeptTestsManage extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        match: PropTypes.shape({
            params: PropTypes.shape({
                id: PropTypes.string.isRequired,
            }).isRequired,
        }).isRequired,
        actions: PropTypes.shape({
            single: PropTypes.func.isRequired,
        }).isRequired,
        adeptTest: PropTypes.shape({
            isLoading: PropTypes.bool.isRequired,
            isLoaded: PropTypes.bool.isRequired,
            data: PropTypes.object,
        }),
    };
    static defaultProps = {};

    componentDidMount = () => {
        this.loadData();
    }

    componentDidUpdate = prevProps => {
        const { match } = this.props;
        const { params } = match;

        if (prevProps.match.params.id !== params.id) {
            this.loadData();
        }
    }

    loadData = () => {
        const { actions, match } = this.props;
        const { params } = match;

        actions.single({ id: params.id });
    }

    render() {
        const { adeptTest, location, history } = this.props;

        return (
            <StyledComponent
                className="admin-adept-tests-manage"
                styles={require('./styles')}
            >
                <Page
                    elementMode={true}
                    data={adeptTest}
                >
                    {adeptTest && adeptTest.data && (
                        <PageContent
                            breadcrumbs={[{
                                to: withVariables(ADMIN_ADEPT_DATA.path),
                                label: 'Kartoteka',
                            }, {
                                to: withVariables(ADMIN_ADEPT_DATA.path, {}, { tab: TAB_ADEPT_TESTS }),
                                label: 'Testy',
                            }, {
                                to: withVariables(ADMIN_ADEPT_TESTS_MANAGE.path, { id: adeptTest.data.id }, { tab: TAB_DATA }),
                                label: `Edytuj test: ${ adeptTest.data.name }`,
                            }]}
                        >
                            <TabNavigation
                                location={location}
                                history={history}
                                headline="Edytuj cel treningowy"
                                tabs={[{
                                    key: TAB_DATA,
                                    label: 'Dane',
                                    children: (
                                        <Editor
                                            location={location}
                                            history={history}
                                            data={adeptTest.data}
                                        />
                                    ),
                                }]}
                            />
                        </PageContent>
                    )}
                </Page>
            </StyledComponent>
        );
    }
}

