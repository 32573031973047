import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { API_RESOURCE_ADEPT_TEST, API_RESOURCE_USERS } from 'Consts/apiResources';
import { ADMIN_ADEPT_TESTS_MANAGE, ADMIN_USERS_MANAGE } from 'Consts/routes';
import { USER_ROLE_ADEPT, USER_ROLE_TRAINER } from 'Consts/userRoles';

import { withVariables } from 'Utils/string';
import { fromSelectObject } from 'Utils/object';
import { toApiFormat as dateToApiFormat } from 'Utils/date';
import { getFullName as getUserFullName } from 'Utils/user';
import { parseQueryToObject } from 'Utils/querystring';

import StyledComponent from 'Components/core/StyledComponent';
import Spinner from 'Components/layoutAdmin/Spinner';
import ElementEditor from 'Components/layoutAdmin/panel/ElementEditor';

export default class AdminAdeptTestsEditor extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        actions: PropTypes.shape({
            create: PropTypes.func.isRequired,
            update: PropTypes.func.isRequired,
            listUsers: PropTypes.func.isRequired,
        }).isRequired,
        data: PropTypes.object,
        predefinedState: PropTypes.object,
    };
    static defaultProps = {
        data: null,
        predefinedState: {},
    };

    state = {
        formState: {},
        products: [],
    };

    componentDidMount = () => {
        const { data, location, predefinedState } = this.props;
        const { search } = location;
        const queryObject = parseQueryToObject(search, true);

        let overrideState = {};
        if(queryObject.adeptId) {
            overrideState.adeptId = queryObject.adeptId;
        }

        this.setState(prevState => ({
            formState: {
                ...prevState.formState,
                ...this.dataToFormState(data || {}),
                ...overrideState,    
                ...predefinedState,                
            },
        }));
    }

    componentDidUpdate = prevProps => {
        const { data } = this.props;

        if (data && JSON.stringify(data) !== JSON.stringify(prevProps.data)) {
            this.setState(prevState => ({
                formState: {
                    ...prevState.formState,
                    ...this.dataToFormState(data),
                },
            }));
        }
    }

    dataToFormState = data => ({
        ...data,
        adeptId: data.adept
            ? { value: data.adept.id, label: getUserFullName(data.adept).label }
            : null,
        trainerId: data.trainer
            ? { value: data.trainer.id, label: getUserFullName(data.trainer).label }
            : null,
        testDate: data.testDate && moment(data.testDate).toDate(),
    })

    onSubmit = formState => {
        const { data } = this.props;

        return data && data.id
            ? this.onUpdate(formState)
            : this.onCreate(formState);
    }

    onCreate = formState => {
        const { actions, history } = this.props;

        return actions.create({
            ...formState,
            adeptId: fromSelectObject(formState.adeptId),
            trainerId: fromSelectObject(formState.trainerId),
            testDate: dateToApiFormat(formState.testDate),
        })
            .then(response => {
                history.push(
                    withVariables(
                        ADMIN_ADEPT_TESTS_MANAGE.path,
                        { id: response.payload[API_RESOURCE_ADEPT_TEST].id }
                    )
                );
            });
    }
    onUpdate = formState => {
        const { data, actions } = this.props;

        return actions.update({
            ...formState,
            id: data.id,
            adeptId: fromSelectObject(formState.adeptId),
            trainerId: fromSelectObject(formState.trainerId),
            testDate: dateToApiFormat(formState.testDate),
        });
    }

    render() {
        const { data, location, history, actions } = this.props;
        const { formState } = this.state;

        const { search } = location;
        const queryObject = parseQueryToObject(search, true);

        if (!formState) {
            return (<Spinner />);
        }

        return (
            <StyledComponent
                className="admin-adept-tests-editor"
                styles={require('./styles')}
            >
                <ElementEditor
                    location={location}
                    history={history}
                    details={[{
                        visible: Boolean(data),
                        label: 'Identyfikator',
                        value: formState.id,
                    }, {
                        visible: Boolean(data && data.adept),
                        label: 'Adept',
                        value: data && data.adept && getUserFullName(data.adept).label,
                        to: data && data.adept && withVariables(ADMIN_USERS_MANAGE.path, { id: data.adept.id }),
                    }, {
                        visible: Boolean(data && data.trainer),
                        label: 'Trener',
                        value: data && data.trainer && getUserFullName(data.trainer).label,
                        to: data && data.trainer && withVariables(ADMIN_USERS_MANAGE.path, { id: data.trainer.id }),
                    }]}
                    forms={[{
                        title: 'Dane',
                        name: 'editor',
                        submitAction: this.onSubmit,
                        data: formState,
                        onStateChange: formState => {
                            this.setState({
                                formState,
                            });
                        },
                        elements: [{
                            type: 'input',
                            name: 'name',
                            label: 'Nazwa testu',
                        }, {
                            type: 'textarea',
                            name: 'description',
                            label: 'Opis testu',
                        }, {
                            type: 'select',
                            name: 'adeptId',
                            label: 'Adept',
                            isVisible: Boolean(!queryObject.adeptId),
                            inputProps: {
                                dynamic: true,
                                onLoad: query => actions.listUsers({
                                    search: query,
                                    enabled: true,
                                    role: USER_ROLE_ADEPT,
                                }),
                                onMapResponse: response => response.payload[API_RESOURCE_USERS].elements,
                                onMapOption: element => ({
                                    value: element.id,
                                    label: getUserFullName(element).label,
                                }),
                            },
                        }, {
                            type: 'select',
                            name: 'trainerId',
                            label: 'Trener',
                            inputProps: {
                                dynamic: true,
                                onLoad: query => actions.listUsers({
                                    search: query,
                                    enabled: true,
                                    role: USER_ROLE_TRAINER,
                                }),
                                onMapResponse: response => response.payload[API_RESOURCE_USERS].elements,
                                onMapOption: element => ({
                                    value: element.id,
                                    label: getUserFullName(element).label,
                                }),
                            },
                        }, {
                            type: 'datePicker',
                            name: 'testDate',
                            label: 'Data testu',
                            inputProps: {
                                datePickerProps: {
                                    showTimeSelect: false,
                                    dateFormat: 'yyyy-MM-dd',
                                },
                            },
                        }, {
                            type: 'input',
                            name: 'rating',
                            label: 'Ocena testu',
                            inputProps: {
                                type: 'number',
                            },
                        }],
                    }]}
                />
            </StyledComponent>
        );
    }
}

